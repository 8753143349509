.firebaseui-container {
  box-shadow: none !important;
}
.firebaseui-button {
  width: 100%;
  margin-left: 0 !important;
  background: #fd5a52 !important;
  border-radius: 10px;
}
.firebaseui-form-actions {
  display: flex !important;
  flex-direction: column !important;
}
.firebaseui-form-actions > button {
  margin: 4px 0;
}
.mdl-button--primary.mdl-button--primary {
  color: #fd5a52 !important;
  background: white !important;
}
.mdl-button {
  border-radius: 10px !important;
}
